.__table {
    &--sm .__player {
        width: 130px !important;
    }
    &--md .__player {
        width: 110px !important;
    }
    &--lg .__player {
        width: 80px !important;
    }
}
