$blue: #375a7f;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff6384;
$orange: #fd7e14;
$yellow: #e7dba6;
$green: #00bc8c;
$teal: #20c997;
$cyan: #3498db;

$gray-100: #bbbbbb;
$gray-200: #aaaaaa;
$gray-300: #999999;
$gray-400: #888888;
$gray-500: #777777;
$gray-600: #666666;
$gray-700: #555555;
$gray-800: #444444;
$gray-900: #333333;
$gray-1000: #222222;
$gray-1100: #111111;

$dark: $gray-1100;

$table-striped-color: $orange;
