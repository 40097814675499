.__room {
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 580px;
    padding: 16px;

    &__session {
        height: 70px;
        margin-bottom: 50px;
    }

    &__table {
        width: 560px;
        height: 300px;
        // background: rgba(255, 255, 255, 0.05);
    }
}
