html,
body,
#root {
    background: $gray-1000;
    height: 100%;
}

input[type='checkbox'] {
    cursor: pointer;
}

.form-check {
    label {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.card-enter {
    opacity: 0;
    transform: rotateY(90deg);
}
.card-enter-active {
    opacity: 1;
    transform: rotateY(0deg);
    transition: all 0.3s ease;
}
.card-exit {
    transform: rotateY(0deg);
}
.card-exit-active {
    transform: rotateY(90deg);
    transition: all 0.3s ease;
}

.table-striped > tbody > tr {
    &:nth-of-type(odd) {
        color: $gray-300;
    }
    &:nth-of-type(even) {
        color: $gray-400;
    }
}

.--with-dash {
    &::after {
        content: ' -';
    }
    &::before {
        content: '- ';
    }
}
